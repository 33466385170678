@import '../../../../variables.scss';

.CustomersTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .MyAutocompleteInput {
        min-width: 200px;
    }
    .MySelectInput {
        min-width: 180px;
    }
}

.CustomersTable__FilterBar__Actions {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.CustomersTable__DataTable {
    .DataTable__DataCell--accessType {
        font-size: 16px;
    }
}

.CustomersTable__PaymentMethod {
    &.default {
        opacity: 0.3;
    }
}

.CustomersTable__Status {
    &.none {
        opacity: 0.3;
    }
}
