@import '../../../../variables.scss';

.SalesOrderWorksheetsDialog {
    padding: 32px;
    width: 500px;
}

.SalesOrderWorksheetsDialog__CloseButton {
    padding: 4px;
    min-width: 0;
    height: auto;
    opacity: 0.5;
}

.SalesOrderWorksheetsDialog__Items {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.SalesOrderWorksheetsDialog__SheetItem {
    border-radius: 5px;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-link-dark;
    font-size: 13px;
    background-color: $color-secondary-lighter;

    &:nth-child(even) {
        background-color: $color-secondary-lightest;
    }

    .icon {
        font-size: 16px;
        margin-left: 12px;
        color: black;
        opacity: 0.4;
    }

    &.SalesOrderWorksheetsDialog__SheetItem--Link {
        &:hover {
            color: $color-link-dark;
            background-color: $color-primary-lighter;
        }
    }

    &.SalesOrderWorksheetsDialog__SheetItem--Error {
        color: $color-danger-base;

        .icon {
            color: $color-danger-base;
        }
    }
}

.SalesOrderWorksheetsDialog__Buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}

.SalesOrderWorksheetsDialog__Buttons__Close {
    width: 128px;
}
