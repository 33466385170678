@import '../../../../variables.scss';

.SalesOrderDocumentsTab {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.SalesOrderDocumentsTab__Heading {
    font-size: 16px;
    margin-bottom: -8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SalesOrderDocumentsTab__TableContainer {
    background-color: $color-secondary-lighter;
    border-radius: 5px;

    .DataTable {
        background-color: transparent;
    }
}

.SalesOrderDocumentsTable__FilterBar {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SalesOrderDocumentsTable__DataTable {
    .DataTable__DataCell--name {
        display: flex;
        align-items: center;
        gap: 12px;

        .Link {
            color: $color-link-dark;
        }
    }
}
