@import '../../../../variables.scss';

.DocumentDetailModal__Frame {
    width: 800px;
    min-height: 614px;
}

.DocumentDetailModal__Body {
    .MyEditModal__Header__PageHeader {
        word-break: break-all;
        width: 100%;
        // .PageHeader__TitleContainer__Title {
        //     // em {
        //         white-space: nowrap;
        //         overflow: hidden;
        //         text-overflow: ellipsis;
        //     // }
        // }
    }
}

.DocumentDetailModal__Main {
    display: flex;
    gap: 32px;
}

.DocumentDetailModal__Main__PropertyContainer {
    flex: 1;
}

.DocumentDetailModal__Link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 200px;
    height: 200px;
    background-color: $color-grey-lightest;
    border-radius: 5px;
    border: 2px solid $color-grey-lighter;
    padding: 12px;
    overflow: hidden;

    .download-icon {
        position: absolute;
        bottom: 8px;
        right: 8px;
        font-size: 30px;
        color: $color-grey-dark;
        background-color: white;

        padding: 6px;
        border-radius: 3px;
    }

    &:hover,
    &:focus {
        .download-icon {
            box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.3);
        }
    }

    .DocumentFileIcon {
        font-size: 80px;
    }
}

.DocumentDetailModal__Link__Preview {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 100%;
}
