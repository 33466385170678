@import '../../../variables.scss';

.ToggleFilter {
    position: relative;
}
.ToggleFilter__OutlinedInput {
    width: 80px;
    min-width: 80px;
    height: 40px;
    box-sizing: border-box;
    position: relative;
}
.ToggleFilter__SwitchWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
