@import '../../../../variables';

.HistoryEntryDisplay:not(:last-child) {
    border-bottom: 1px solid $color-grey-lighter;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.HistoryEntryDisplay__Title {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

.HistoryEntryDisplay__Timestamp {
    color: $color-grey-base;
    font-size: 12px;
}

.HistoryEntryDisplay__Changes {
    border-left: 3px solid rgba(0, 0, 0, 0.1);
    padding-left: 8px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.HistoryEntryDisplay__FieldDetail {
    display: flex;
    gap: 0 8px;
    flex-wrap: wrap;
    align-items: center;
}

.HistoryEntryDisplay__FieldDetail__Field {
    font-weight: $font-weight-semi-bold;
    color: $color-primary-dark;
}
.HistoryEntryDisplay__FieldDetail__Content {
    // Hide text after 3 lines
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    display: flex;
    align-items: center;
}
