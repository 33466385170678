@import '../../../../variables.scss';

.DocumentsTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
}

.DocumentsTable__DataTable {
    .DataTable__DataCell--name {
        display: flex;
        align-items: center;
        gap: 12px;

        .Link {
            color: $color-link-dark;
        }
    }
}

.DocumentsTable__ArchiveButton {
    padding: 0 4px;
    min-width: 0;
    height: auto;

    .MyButton__Inner__IconLeft {
        font-size: 18px;
    }
}
