@import '../../variables.scss';

.PropertyEditSelect__MySelectInput {
    min-width: 200px;
}

.PropertyEditSelect--full-width {
    width: 100%;
}

.PropertyEditSelect--full-width__InputWrapper {
    width: 100%;

    .PropertyEditSelect__MySelectInput {
        width: 100%;
    }
}
