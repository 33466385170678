@import '../../../../variables.scss';

.StocktakeDetailModal__Frame {
    width: 800px;
}

.StocktakeDetailModal__CountSheetFilters {
    margin-top: 24px;
}
.StocktakeDetailModal__CountSheetTable {
    margin-top: 12px;
}

.StocktakeDetailModal__EditModal__Frame {
    width: 600px;
}

.StocktakeDetailModal__ImportErrorMessage {
    background-color: $color-grey-lighter;
    padding: 4px;
    border-radius: 5px;
}
