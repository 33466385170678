@import '../../variables.scss';

.PropertyEditCheckbox__DisplayCheck {
    font-size: 24px;
}

.PropertyEditCheckbox__Input {
    .MuiCheckbox-root {
        padding: 0;
    }
    .MuiSvgIcon-root {
        font-size: 24px;
    }
}
