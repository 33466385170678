@import '../../../../variables.scss';

.WorkItemStatusBadge--badge {
    padding: 2px 12px;
    border-radius: 24px;
    display: inline-block;
    font-size: 12px;
    color: white;
    background-color: $color-secondary-base;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 24px;

    &.UNSTARTED {
        background-color: white;
        color: $color-primary-base;
        border: 1px solid $color-primary-light;
    }
    &.INPROGRESS {
        background-color: $color-accent-base;
    }
    &.ONHOLD {
        background-color: $color-primary-light;
    }
    &.COMPLETED {
        background-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
}

.WorkItemStatusBadge--title {
    font-size: 16px;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    .WorkItemStatusBadge__Label {
        text-align: left;
        font-size: 16px;
        font-weight: $font-weight-bold;
    }
}

.WorkItemStatusBadge--icon {
    font-size: 16px;
    text-align: center;
    margin: 0;
    align-items: center;
}

.WorkItemStatusBadge__Icon {
    border-radius: 100%;
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 15px;
        height: 15px;
    }

    &.UNSTARTED {
        background-color: transparent;
        border: 5px solid $color-primary-light;
    }
    &.ONHOLD {
        background-color: $color-primary-light;
    }
    &.INPROGRESS {
        background-color: $color-accent-base;
    }
    &.COMPLETED {
        background-color: $color-accent-light;
        color: $color-accent-dark;
    }
}
