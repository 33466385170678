@import '../../variables.scss';
.DataTableCriteria {
    display: flex;
    flex-direction: row;
    gap: 8px 6px;
    flex-wrap: wrap;
}

@keyframes criteria-refresh-icon-spin {
    from {
        transform: rotateZ(0);
    }
    to {
        transform: rotateZ(-359deg);
    }
}

.MyButton.DataTableCriteria__ResetButton,
.MyButton.DataTableCriteria__RefreshButton {
    padding: 0 8px;
    min-width: 0;

    &.refreshing {
        .MyButton__Inner__IconRight {
            animation: criteria-refresh-icon-spin 1s infinite;
            color: $color-grey-light;
        }
    }
}
