@import '../../../../variables.scss';

.WorkstationMain__ChangeWorkstationModal__Body {
    width: 600px;
}

.WorkstationMain__TabHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.WorkstationMain__TabHeader__Title {
    margin-top: 8px;
    margin-bottom: 0;
    white-space: nowrap;
}

.WorkstationMain__TabHeader__ActiveFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
}

.WorkstationMain__TabHeader__ActiveFilters__FilterButton {
    color: white;
    border-radius: 60px;
    padding: 4px 48px 4px 20px;
    position: relative;
    font-weight: $font-weight-normal;
    text-align: left;

    .label {
        color: rgba(255, 255, 255, 0.7);
        font-size: 11px;
        line-height: 13px;
    }

    .value {
        line-height: 16px;
    }

    .remove {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.WorkstationMain__Group__DateHeader {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 0;
    padding: 2px 12px;
    background-color: $color-secondary-base;
    color: white;

    .icon {
        font-size: 20px;
        opacity: 0.5;
    }

    .late-badge {
        background-color: $color-warning-base;
        color: $color-warning-darker;
        padding: 1px 8px;
        font-size: 11px;
        font-weight: $font-weight-normal;
        border-radius: 24px;
    }
    .today-badge {
        background-color: $color-accent-base;
        color: $color-accent-darker;
        padding: 1px 8px;
        font-size: 11px;
        font-weight: $font-weight-normal;
        border-radius: 24px;
    }
}
