@import '../../../../variables.scss';

.StocktakeTable {
    .DataTable__DataCell--status {
        width: 1px;
        white-space: nowrap;
    }
    .StocktakeTable__NotesDisplay {
        // Hide text after 2 lines
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
}

.StocktakeTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .DataTableFilters__Field--SelectFilter--parentLocationId {
        .MySelectInput {
            min-width: 136px;
        }
    }
}
