@import '../../variables.scss';

.MyCheckboxTreeItem {
    padding: 1px 0;
}

.MyCheckboxTreeItem__Main {
    display: flex;
}

.MyCheckboxTreeItem__Main__Expander {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 20px;
}

.MyCheckboxTreeItem__Main__LeafMarker {
    margin: 2px 0px 0 8px;
    width: 12px;
    height: 12px;
    border-bottom: 1px dashed $color-grey-base;
    border-left: 1px dashed $color-grey-base;
}

.MyCheckboxTreeItem__Main__Button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: 0;

    &[disabled] {
        color: black;
        opacity: 0.6;
    }

    &:not([disabled]) {
        cursor: pointer;
    }
}

.MyCheckboxTreeItem__Main__Button__Checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.unselected {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    &.selected {
        font-size: 18px;
        background-color: $color-checked-green;
        color: white;
    }
    &.partial {
        border: 1px solid rgba(0, 0, 0, 0.2);

        font-size: 12px;
        color: $color-checked-green;
    }
}

.MyCheckboxTreeItem__Children {
    padding-left: 28px;
}
