@import '../../variables.scss';

.MyTabs__Tabs {
    display: flex;
    flex-direction: row;
    gap: 48px;
    border-bottom: 1px solid $color-grey-light;
    margin-bottom: 16px;
    z-index: 1;
}

.MyTabs__Tabs__TabButton {
    display: flex;
    background: transparent;
    align-items: center;
    font-size: 14px;
    font-family: inherit;
    justify-content: center;
    height: 54px;
    border: 4px solid transparent;
    color: $color-grey-base;
    font-weight: $font-weight-bold;

    &.inactive[disabled] {
        color: $color-grey-light;
    }
    &:not([disabled]) {
        cursor: pointer;

        &.inactive:hover {
            border-bottom-color: $color-grey-base;
        }
    }

    &.active {
        color: $color-primary-base;
        border-bottom-color: $color-primary-base;
    }
}

.MyTabs__TabPanels {
    overflow: auto;
}

.MyTabs__TabPanel {
    &.hidden {
        display: none;
    }
}
