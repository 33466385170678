@import '../../../../variables.scss';

.WorkstationStepStatusArea {
    margin: 0 -32px;
}

.WorkstationStepStatusArea__Warning {
    background-color: $color-warning-light;
    color: $color-warning-darker;
    padding: 12px 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ul,
    p {
        margin: 0;
    }
}

.WorkstationStepStatusArea__StatusBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;

    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.COMINGUP {
        background-color: $color-warning-lighter;
        border-color: $color-warning-light;
    }
    &.READY,
    &.ONHOLD {
        background-color: $color-primary-lightest;
        border-color: $color-primary-lighter;
    }
    &.INPROGRESS {
        background-color: $color-accent-lightest;
        border-color: $color-accent-light;
    }
    // &.SKIPPED,
    // &.COMPLETED {
    //    use default styling
    // }
}

.WorkstationStepStatusArea__StatusBar__Main {
    flex: 1;
}

.WorkstationStepStatusArea__StatusBar__Buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
