@import '../../variables.scss';

.MyCalendarPicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.23); // to match mui input
    border-radius: 5px;
    background-color: white;
    padding: 0 12px;
    min-width: 264px;
}

.MyCalendarPicker__PrevButton,
.MyCalendarPicker__NextButton {
    min-width: 0;
    padding: 12px;
}

.MyCalendarPicker__Button {
    height: 52px;
    padding: 12px;
    min-width: 0;

    .MyButton__Inner {
        gap: 16px;
    }
}
