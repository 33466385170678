@import '../../../../variables.scss';

.WorkItemStepHistoryModal__Frame {
    width: 700px;
}

.WorkItemStepHistoryModal__Data Table {
    .DataTable__DataCell:first-child {
        padding-left: 12px;
    }
    .DataTable__DataCell:last-child {
        padding-right: 12px;
    }
}
