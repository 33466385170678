@import '../../variables.scss';

.PropertyEditDateTime__InputWrapper {
    display: flex;
    gap: 6px;
    width: 412px;
}

.PropertyEditDateTime__Input {
    width: 200px;
}
