@import '../../../../variables.scss';

.PackageDetailModal__Frame {
    width: 900px;
}

.PackageDetailModal__PageHeader__Actions {
    display: flex;
    gap: 6px;
}

.PackageDetailModal__Body {
    .PropertyEdit__Label,
    .PropertyDisplay__Label {
        width: 120px;
        min-width: 120px;
    }
}

.PackageDetailModal__WorkItemsTab__Title {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 24px;
}
.PackageDetailModal__WorkItemsTab__Description {
    margin: 0;
    color: $color-grey-base;
}

.PackageDetailModal__WorkItemsTab__DataTable {
    .DataTable__EmptyStateWrapper {
        text-align: left;
        color: $color-grey-light;
    }
}

.PackageDetailModal__LocationBreadcrumbs {
    background-color: transparent;
}

// .PackageDetailModal__Tabs {
//     margin-top: 24px;
//     .MyTabs__Tabs__TabButton--work-items {
//         flex-direction: column;
//         gap: 8px;

//         .ShipmentWorkItemsProgress__Counts {
//             color: $color-grey-base;
//         }
//     }
// }

// .PackageDetailModal__WorkOrdersTab__WorkItemStatusCell {
//     display: flex;
//     justify-content: flex-start;
// }
