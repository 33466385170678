@import '../../../../variables.scss';

.SalesOrdersTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}
.SalesOrdersTable__StatusCell {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;

    @media #{$large-only} {
        flex-direction: row;
    }
}

.SalesOrdersTable__StatusCell__Archived {
    font-size: 12px;
    background-color: $color-warning-base;
    color: $color-warning-darker;
    border-radius: 12px;
    padding: 2px 12px;
    font-weight: $font-weight-semi-bold;
}
