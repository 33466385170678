@import '../../variables.scss';

.PropertyDisplay {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.PropertyDisplay__Label {
    font-size: 14px;
    color: $color-grey-base;
}

.PropertyDisplay__Label__Hint {
    font-size: 12px;
    color: $color-grey-base;
    display: block;
}

.PropertyDisplay__Label__Text__InlineHint {
    font-size: 12px;
}

.PropertyDisplay__Value {
    // retain and display line breaks from content
    white-space: pre-wrap;
    font-size: 14px;

    .MuiInputBase-root {
        background-color: white;
    }

    .MyEditModal__Body & {
        // save room for input when in edit mode
        min-height: 40px;
    }

    &.empty {
        color: $color-grey-light;
    }
}
