@import '../../../../variables.scss';

.AccountingConnectionStatusBadge {
    border-radius: 24px;
    display: inline-block;
    color: white;
    background-color: $color-secondary-base;
    text-align: center;
    white-space: nowrap;

    &.AccountingConnectionStatusBadge--normal {
        padding: 2px 16px;
        font-size: 14px;
    }

    &.AccountingConnectionStatusBadge--small {
        padding: 2px 12px;
        font-size: 12px;
    }

    &.AccountingConnectionStatusBadge--PENDING {
        color: $color-warning-darker;
        background-color: $color-warning-base;
    }
    &.AccountingConnectionStatusBadge--FAILED {
        color: $color-danger-base;
        background-color: $color-danger-lighter;
        border: 1px solid $color-danger-base;
    }
    &.AccountingConnectionStatusBadge--FULFILLED {
        background-color: $color-checked-green;
    }
    &.AccountingConnectionStatusBadge--EXTERNAL {
        color: $color-primary-dark;
        background-color: white;
        border: 1px solid $color-primary-base;
    }
}
