@import '../../../../variables.scss';

.ScheduleMoveToDateDialog {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 412px;
}

// Move to date dialog
.ScheduleMoveToDateDialog__Title {
    margin: 0;
}

.ScheduleMoveToDateDialog__CalendarWrapper {
    position: relative;
}

.ScheduleMoveToDateDialog__Calendar {
    border-radius: 5px;
    border: 1px solid $color-grey-lighter;
}

.ScheduleMoveToDateDialog__CalendarWrapper__Loading {
    position: absolute;
    top: 80px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1;
}

.ScheduleMoveToDateDialog__Error {
    position: absolute;
    top: 80px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1;

    .ErrorContent__Message {
        font-size: 12px;
    }
}

.ScheduleMoveToDateDialog__SelectedDate {
    background-color: $color-secondary-lighter;
    border-radius: 5px;
    padding: 4px 12px;
    text-align: center;
}

.ScheduleMoveToDateDialog__Buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
