@import '../../variables.scss';

.ProfilePage__Container {
    background-color: white;
    padding: 24px;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 5px;
}

.ProfilePage__Actions {
    display: flex;
}

.ProfilePage__Main {
    flex: 1;
    display: flex;
    gap: 40px;
}

.ProfilePage__Main__Badge {
    width: 80px;
    height: 80px;
    background-color: black;
    color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: $font-weight-semi-bold;
}

.ProfilePage__Main__Fields {
    flex: 1;
}
