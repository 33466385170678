@import '../../../../variables.scss';

.InventoryTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}

.InventoryTable__FilterBar__Actions {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.InventoryTable__StockLevelSelectFilter {
    min-width: 200px;
}
