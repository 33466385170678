@import '../../../../variables.scss';

.LocationsTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .DataTableFilters__Field--SelectFilter--canStore,
    .DataTableFilters__Field--SelectFilter--canAllocate {
        .MySelectInput {
            min-width: 136px;
        }
    }
}
