@import '../../../../variables.scss';

.StocktakeEditModal__Frame {
    width: 600px;
}

.StocktakeEditModal__TopFields {
    display: flex;
    gap: 24px;
}
