@import '../../../../variables.scss';

.StocktakeCountSheetModal__Frame {
    width: 1100px;
}

.StocktakeCountSheetModal__InventoryTable {
    .DataTable__Body--Refreshing {
        // disable the opacity change while refreshing this table
        opacity: 1;
    }
    .DataTable__Col--expectedQuantity {
        width: 120px;
        text-align: center;
    }
    .DataTable__Col--countedQuantity {
        width: 96px;
        text-align: center;

        .MuiInputBase-input {
            text-align: center;
        }
    }
    .DataTable__Col--status {
        width: 48px;
        padding-right: 8px !important;
        text-align: right;
        overflow: hidden;
    }
}
