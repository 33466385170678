@import '../../../../variables.scss';

.WorkstationGoToItemModal__Barcode__Frame {
    width: 360px;
}

.WorkstationGoToItemModal__Barcode__Body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    align-items: center;
}

.WorkstationGoToItemModal__Barcode__Title {
    line-height: normal;
    margin: 0;
    font-size: 24px;
}

.WorkstationGoToItemModal__Barcode__Prompt {
    margin: 0;
}

.WorkstationGoToItemModal__Barcode__Image {
    margin: 24px 0;
}

.WorkstationGoToItemModal__Barcode__Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.WorkstationGoToItemModal__Barcode__NotFound {
    width: 100%;
    position: absolute;
    top: -9px;
    transform: translateY(-100%);
}

.WorkstationGoToItemModal__Barcode__BarcodeInput {
    width: 100%;
    input {
        text-align: center;
    }
}

.WorkstationGoToItemModal__Results__Frame {
    width: 600px;
}
