@import '../../../../variables.scss';

.StocktakeStatusBadge {
    padding: 2px 12px;
    border-radius: 24px;
    display: inline-block;
    font-size: 12px;
    color: white;
    background-color: $color-secondary-base;
    white-space: nowrap;
    text-align: center;

    &.PREPARATION {
        background-color: white;
        color: $color-primary-base;
        border: 1px solid $color-primary-light;
    }
    &.INPROGRESS {
        background-color: $color-primary-light;
    }
    &.COMPLETE {
        background-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
}

.StocktakeStatusBadge--Archived {
    background-color: $color-warning-light;
    border-color: $color-warning-light;
    color: $color-warning-dark;
}
