@import '../../variables.scss';

.MyTextInput {
    .MuiOutlinedInput-root {
        &:not(.MuiInputBase-multiline) {
            &.MuiInputBase-sizeSmall {
                height: 40px;
            }
        }
    }

    // color input
    &--type-color {
        width: 120px;

        .MuiInputBase-input {
            box-sizing: border-box;
            padding: 8px;
            height: 52px;
        }
    }

    &--empty input[type='color']::-webkit-color-swatch {
        background-color: transparent !important;
    }

    // Left Icon stuff
    .MuiInputBase-inputAdornedStart {
        padding-left: 12px;
    }
    .MuiInputAdornment-positionStart {
        margin-right: 0;
    }
    .MyTextInput__LeftIcon {
        width: 22px;
        height: 22px;
    }
}
