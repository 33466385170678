@import '../../../../variables.scss';

.InventoryDetailsStockLevels {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.InventoryDetailsStockLevels__Total {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;

    .total {
        padding-right: 12px;
    }
}

.InventoryDetailsStockLevels__Warehouse__Header {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;
    background-color: $color-secondary-light;
    padding: 4px 16px;
}

.InventoryDetailsStockLevels__Location {
    display: flex;
    justify-content: space-between;

    padding: 4px 16px;
    background-color: $color-secondary-lighter;

    &:nth-child(even) {
        background-color: $color-secondary-lightest;
    }
}

.InventoryDetailsStockLevels__Location__Path {
    display: flex;
    gap: 6px;
    align-items: center;

    .spacer {
        color: rgba(0, 0, 0, 0.5);
    }
}

.InventoryDetailsStockLevels__Location__Count__Button {
    padding: 0;
    min-width: 0;
}
