@import '../../variables.scss';

.InfoGridTable {
    display: table;
    width: 100%;
    border: 1px solid $color-secondary-lighter;
    border-radius: 7px;
    overflow: hidden;
    font-size: 14px;
}

.InfoGridTable__InfoGridRow {
    display: table-row;
    background-color: $color-secondary-lightest;

    &:nth-child(odd) {
        background-color: $color-secondary-lighter;
    }

    .InfoGridTable__InfoGridRow__Label {
        display: table-cell;
        padding: 5px 12px;
        color: $color-grey-base;
        width: 200px;
        line-break: anywhere;
    }
    .InfoGridTable__InfoGridRow__Value {
        display: table-cell;
        line-break: anywhere;
        padding-block: 5px;
        padding-right: 12px;
    }
}
