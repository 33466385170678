@import '../../variables.scss';

.MessagePanel {
    padding: 16px;
    border-radius: 6px;

    p,
    ul {
        margin: 0;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }
}

.MessagePanel--info {
    background-color: $color-primary-lightest;
    border: 1px solid $color-primary-base;
    color: $color-primary-dark;
}

.MessagePanel--warning {
    background-color: $color-warning-lighter;
    border: 1px solid $color-warning-base;
    color: $color-warning-darker;
}

.MessagePanel--error {
    background-color: $color-danger-lighter;
    border: 1px solid $color-danger-base;
    color: $color-danger-darker;
}

@keyframes shake-anim {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(-20px);
    }
    66% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0);
    }
}
.MessagePanel--shake {
    .MessagePanel__Inner {
        animation: shake-anim 0.25s ease;
    }
}
