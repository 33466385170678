@import '../../../../variables.scss';

.SalesOrderAccountingTab__Heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    h2 {
        font-size: 16px;
        margin: 0;
    }
}
