@import '../../../../variables.scss';

.ShipmentsTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .DataTableCriteria__Field--hideCompleted .MySelectInput {
        width: 170px;
    }
}

.ShipmentsTable__FilterBar__Actions {
    display: flex;
    flex-direction: row;
    gap: 6px;
}
