@import '../../../../variables.scss';

.LocationDisplay {
    display: flex;
    // align-items: center;
    // gap: 6px;
}

.LocationDisplay__Path {
    margin-right: 6px;
    color: $color-grey-base;
    display: flex;
    align-items: center;
    gap: 6px;
}
