@import '../../variables.scss';

$datatable-header-bg: $color-secondary-light;
$datatable-body-bg: white; //$color-secondary-lightest;
$datatable-body-bg-alt: $color-secondary-lightest;

// Datatable stuff - to be moved
.DataTable {
    display: table;
    width: 100%;
    table-layout: auto;
    background-color: $datatable-body-bg;
}

.DataTable__LoadingWrapper {
    text-align: center;
    margin: 64px 0;
    display: flex;
    justify-content: center;
}

.DataTable__Error {
    padding: 24px 0;
}

// defer fade in to prevent flickering as table refreshes
@keyframes emptyStateFadeIn {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.DataTable__EmptyStateWrapper {
    text-align: center;
    padding: 64px 0;
    animation: emptyStateFadeIn 0.5s ease;
    position: relative;

    h2 {
        font-weight: $font-weight-bold;
        font-size: 16px;
        margin: 0 0 8px 0;
    }
    p {
        font-size: 13px;
        color: $color-grey-base;
        margin: 0;
    }
}

.DataTable__HeaderRow {
    display: table-row-group;
    position: relative;

    &.DataTable__HeaderRow--sticky {
        position: sticky;
        z-index: 1;
        top: -1px;
    }
}

.DataTable__StickyHeaderRuler {
    position: absolute;
    pointer-events: none;
    width: 5px;
    bottom: 100%;
    height: $topBarHeight + 1px;
}

.DataTable__HeaderRow__Columns {
    display: table-row;
}

.DataTable__HeaderCell {
    display: table-cell;
    padding: 16px 8px;
    font-size: 13px;
    line-height: 19px;
    background-color: $datatable-header-bg;
    font-weight: $font-weight-semi-bold;
    position: relative;
    color: $color-secondary-dark;
    white-space: nowrap;

    &:first-child {
        padding-left: 24px;
        border-radius: 3px 0 0 0;

        .DataTable__HeaderRow--stuck & {
            box-shadow: -32px 0px 0 0 $color-secondary-light;
        }
    }
    &:last-child {
        padding-right: 24px;
        border-radius: 0 3px 0 0;
        &.DataTable__HeaderCell--with-refresh {
            padding-right: 60px; // space for refresh button
        }

        .DataTable__HeaderRow--stuck & {
            box-shadow: 32px 0px 0 0 $color-secondary-light;
        }
    }

    &.DataTable__HeaderCell--sortable label {
        cursor: pointer;
    }

    &.DataTable__HeaderCell--sort-ASC label {
        &:after {
            content: '\25BE'; // ▾
            display: inline-block;
            margin-left: 8px;
            transform: scaleY(-100%);
        }
    }
    &.DataTable__HeaderCell--sort-DESC label {
        &:after {
            content: '\25BE'; // ▾
            display: inline-block;
            margin-left: 8px;
        }
    }
}

@keyframes refresh-icon-spin {
    from {
        transform: rotateZ(0);
    }
    to {
        transform: rotateZ(-359deg);
    }
}

.DataTable__RefreshButton {
    position: absolute;
    top: 11px;
    right: 16px;
    padding: 0;
    min-width: 0;
    font-size: 24px !important;
    color: $color-primary-base !important;

    &.refreshing {
        .MyButton__Inner__IconRight {
            animation: refresh-icon-spin 1s infinite;
            color: $color-grey-light;
        }
    }
}

.DataTable__Body {
    display: table-row-group;
}

.DataTable__Body--Refreshing {
    opacity: 0.5;
}

.DataTable__DataRow {
    display: table-row;
}

.DataTable__DataRow--clickable {
    cursor: pointer;
}

.DataTable__DataCell {
    display: table-cell;
    padding: 10px 8px;
    overflow: hidden;
    font-size: 13px;
    vertical-align: middle;
    border-bottom: 1px solid $color-secondary-lighter;

    &:empty {
        padding: 0;
    }

    &.DataTable__DataCell--empty {
        color: rgba(0, 0, 0, 0.5);
    }

    .DataTable--zebra & {
        border-bottom: 0;
    }

    .DataTable--zebra .DataTable__DataRow:nth-child(even):not(.DataTable__DataRow--highlighted) & {
        background-color: $datatable-body-bg-alt;
    }

    .DataTable__DataRow--highlighted & {
        background-color: $color-secondary-light;
    }

    &:first-child {
        padding-left: 24px;
    }
    &:last-child {
        padding-right: 24px;
        // When the last column is center aligned and the table also has a refresh button in the header
        // then the last cell needs to have some extra padding
        .DataTable--refresh-align-fix & {
            padding-right: 60px;
        }
    }

    .DataTable__DataRow:last-child & {
        border-bottom: 0;
    }
}

.DataTable__DataCell--RowEndIcon {
    width: 1px;
    white-space: nowrap;
    text-align: right;
    &:last-child {
        padding-right: 18px;
    }
    svg {
        font-size: 20px;
    }
}

.DataTable__HeaderCell--Select,
.DataTable__DataCell--Select {
    position: relative;
    width: 60px;
}

.DataTable__SelectButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    .icon {
        content: '';
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 100%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }

    &.selected .icon {
        font-size: 24px;
        background-color: $color-accent-base;
        color: white;
    }
}
