@import '../../../../variables.scss';

.WorkflowItemSplitEditModal__Frame {
    width: 800px;
}

.WorkflowItemSplitEditModal__Tabs {
    margin-top: 16px;
}

.WorkflowItemSplitEditModal__SectionHeader {
    font-size: 20px;
    margin-bottom: 12px;
}

.WorkflowItemSplitEditModal__SaveButton {
    min-width: 120px;
}

.WorkflowItemSplitEditModal__InputTable {
    display: table;
    width: 100%;
    margin-bottom: 12px;
    background-color: $color-secondary-lighter;
    padding: 12px;
    border-radius: 5px;
}

.WorkflowItemSplitEditModal__InputTable__Empty {
    text-align: center;
    color: $color-secondary-base;
}

.WorkflowItemSplitEditModal__InputRow {
    display: table-row;
}

.WorkflowItemSplitEditModal__InputRow__Cell {
    vertical-align: top;
    display: table-cell;
    padding: 6px 4px;

    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }

    &.WorkflowItemSplitEditModal__InputRow__Cell--icon {
        text-align: center;
        vertical-align: middle;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiOutlinedInput-input {
        background-color: white;
    }
}

.MyButton.WorkflowItemSplitEditModal__InputRow__RemoveButton {
    padding: 12px;
    min-width: 0;
    width: 24px;
    height: 24px;
    position: relative;
    top: 8px;
}

.WorkflowItemSplitEditModal__GlobalsTab__Warning {
    margin-bottom: 12px;
}

.WorkflowItemSplitEditModal__MappingSection {
    background-color: $color-secondary-light;
    padding: 24px;
    margin-bottom: 12px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .MuiOutlinedInput-input {
        background-color: white;
    }
}

.WorkflowItemSplitEditModal__MappingSection__TopContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;
}

.WorkflowItemSplitEditModal__MappingSection__RemoveButton {
    position: absolute;
    top: 12px;
    right: 12px;
    min-width: 0;
    width: 32px;
    height: 32px;
    padding: 0;

    .MyButton__Inner__IconLeft {
        opacity: 0.7;
    }
}
