@import '../../variables.scss';

.body--ResetPasswordPage {
    background-color: $color-primary-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.ResetPasswordPage {
    width: 512px;
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.ResetPasswordPage__Logo {
    width: 95px;
    height: 99px;
    margin-bottom: 16px;
}

.ResetPasswordPage__Title {
    margin: 0;
}

.ResetPasswordPage__Form {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 8px;
}

.ResetPasswordPage__BackToLogin {
    text-align: center;
}
