@import '../../../../variables.scss';

.WorkstationStepsTable {
    .DataTable__DataCell--status {
        width: 1px;
        white-space: nowrap;
        text-align: right;
    }
    .DataTable__Col--filter {
        width: 1px;
    }
}

.WorkstationStepsTable__StatusBadge {
    padding: 2px 12px;
    border-radius: 24px;
    display: inline-block;
    font-size: 12px;
    color: white;
    background-color: $color-secondary-base;
    white-space: nowrap;

    &.READY {
        background-color: white;
        color: $color-primary-base;
        border: 1px solid $color-primary-light;
    }
    &.COMINGUP {
        background-color: $color-warning-base;
        color: $color-warning-darker;
    }
    &.INPROGRESS {
        background-color: $color-accent-base;
    }
    &.ONHOLD {
        background-color: $color-primary-light;
    }
    &.COMPLETED {
        // background-color: white;
        // border: 1px solid $color-accent-dark;
        // color: $color-accent-dark;
        background-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
    &.SKIPPED {
        // background-color: $color-secondary-light;
        // color: $color-secondary-base;
        background-color: $color-danger-lighter;
        color: $color-danger-dark;
    }
}

.WorkstationStepsTable__LabelValueCell {
    display: flex;
    flex-direction: column;
}

.WorkstationStepsTable__LabelValueCell__Label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;

    @media #{$medium-down} {
        word-break: break-all;
    }
}
.WorkstationStepsTable__LabelValueCell__Value {
    overflow: hidden;
    line-height: 19px;
    max-height: 19px; // just enough room for 1 line
    text-overflow: ellipsis;

    .empty {
        color: rgba(0, 0, 0, 0.4);
    }

    @media #{$medium-down} {
        word-break: break-all;
    }
}

.WorkstationStepsTable__FilterMenuButton {
    .MyButton {
        padding: 0 4px;
        min-width: 0;
    }
}

.WorkstationStepsTable__FilterMenuItemLabel {
    display: flex;
    gap: 24px;
    align-items: center;
    line-height: 24px;
    justify-content: space-between;

    label {
        color: $color-grey-base;
        font-size: 12px;
    }
}

.WorkstationStepsTable__DataRow--collapsed {
    .DataTable__DataCell {
        // background-color: $color-secondary-light !important;
        background-color: $color-grey-lighter !important;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .DataTable__DataCell:not(:first-child) {
        // hide cell contents
        > * {
            display: none;
        }
    }
    .DataTable__DataCell:first-child {
        column-span: all;

        .WorkstationStepsTable__LabelValueCell__Value {
            display: none;
        }
    }

    & + .WorkstationStepsTable__DataRow--collapsed {
        .DataTable__DataCell {
            border-top: 0;
        }
    }
}
