@charset "utf-8";
@import './variables.scss';

html {
    font-size: 14px;
}

body {
    all: unset;
    box-sizing: border-box;
    font-family: $fontFamily;
    font-size: 14px;
    background-color: $color-grey-lightest;
    line-height: 1.5;

    &.scroll-lock {
        // set by useScrollLock hook
        overflow: hidden;
    }
}

#modal-root {
    position: relative;
    z-index: 2;
}
#modal-root-top {
    position: relative;
    z-index: 3;
}

ul {
    padding-inline-start: 24px;
}

a {
    color: inherit;
    text-decoration: inherit;
    display: inline;
    cursor: pointer;
}
.Link,
.Link {
    color: $color-link-base;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    font-family: $fontFamily;
    font-size: inherit;

    &:hover,
    &:focus {
        color: $color-link-dark;
        text-decoration: underline;
    }
}
