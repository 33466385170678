@import '../../../../variables.scss';

.PackagesTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .DataTableCriteria__Field--hideCompleted .MySelectInput {
        width: 170px;
    }
}

.PackagesTable__FilterBar__Actions {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.PackagesTable__ShipmentDisplay {
    .top {
        display: flex;
        align-items: center;
        gap: 6px;
    }
    .tuid {
        color: rgba(0, 0, 0, 0.5);
        font-size: 11px;
    }
    .name {
        font-weight: $font-weight-semi-bold;
    }
}
