@import '../../variables.scss';

.SaveStatusIndicator {
    max-height: 40px;
}
.SaveStatusIndicator__Success {
    color: $color-checked-green;
    font-size: 28px;
    overflow: hidden;
    svg {
        animation: saveStatusIndicatorSuccessAnim 1s ease-in-out forwards;
    }
}
.SaveStatusIndicator__Error {
    color: $color-error;
    font-size: 28px;
    overflow: hidden;
}

@keyframes saveStatusIndicatorSuccessAnim {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}
