@import '../../variables.scss';

.PropertyEditColor__Display {
    display: flex;
    gap: 12px;
}

.PropertyEditColor__Display__Swatch {
    border: 1px solid $color-grey-light;
    border-radius: 5px;
    width: 24px;
    height: 24px;
}

.PropertyEditColor__InputWrapper {
    position: relative;
}

.MyButton.PropertyEditColor__ClearButton {
    position: absolute;
    top: 8px;
    left: 132px;
    padding: 0;
    border-radius: 100%;
    background-color: $color-grey-base;
    color: white;
    min-width: 0;
    min-height: 0;
    width: 24px;
    height: 24px;
    opacity: 0.6;

    .MyButton__Inner__IconLeft {
        font-size: 16px;
    }
}
