@import '../../../../variables.scss';

.LabelPrintDialog {
    width: 400px;
}

.LabelPrintDialog__InputWrapper {
    background-color: $color-secondary-lighter;
    padding: 16px 16px 24px;
    border-radius: 5px;
    min-height: 108px;

    .PropertyEdit {
        margin: auto;
    }
}

.LabelPrintDialog__Buttons {
    margin-top: 32px;
    display: flex;
    gap: 6px;
    justify-content: flex-end;

    &.specify-copies {
        justify-content: space-between;
    }
}

.LabelPrintDialog__Buttons__Save {
    min-width: 140px;
}
