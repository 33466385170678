@import '../../../../variables.scss';

.LocationBreadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px 3px;
    font-size: 16px;
    background-color: $color-grey-lightest;
    border-radius: 3px;
    padding: 2px 32px;
    margin: 0 -32px;

    .Link {
        display: block;
    }
    .current {
        font-weight: $font-weight-semi-bold;
        color: $color-secondary-base;
    }
}
