@import '../../../../variables.scss';

.WorkstationStepDetailsModal__Frame {
    width: 1000px;
    display: flex;
    flex-direction: column;
}

.WorkstationStepDetailsModal__Body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
}

.WorkstationStepDetailsModal__Header {
    display: flex;
    justify-content: space-between;
}

.WorkstationStepDetailsModal__Header__PageHeader {
    margin: 0;
}

.WorkstationStepDetailsModal__Header__Actions {
    display: flex;
    gap: 6px;
}

.WorkstationStepDetailsModal__OrdersContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .PropertyDisplay {
        flex: 1;
    }
}

.WorkstationStepDetailsModal__Skeleton {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 520px;
}

.WorkstationStepDetailsModal__Main {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
}

.WorkstationStepDetailsModal__Main__Details {
    flex: 1;
    padding-right: 24px;
    display: flex;
    flex-direction: column;

    .PropertyDisplay__Label {
        width: 38.2%; // golden ratio ϕ
        white-space: unset;
    }
}

.WorkstationStepDetailsModal__Main__Details__Description {
    margin-top: 0;
    font-size: 18px;
}

.WorkstationStepDetailsModal__Main_DetailsField {
    flex-grow: 1;
}

.WorkstationStepDetailsModal__Footer {
    position: sticky;
    bottom: -24px;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 32px;
    margin: 0 -32px -32px;
    border-radius: 0 0 12px 12px;
    display: flex;
    justify-content: space-between;
}

.WorkstationStepDetailsModal__Footer__Right {
    display: flex;
    gap: 6px;
}

.WorkstationStepDetailsModal__PrintLabelsButton {
    .MyButton__Inner__IconRight {
        font-size: 16px;
    }
}

.WorkstationStepDetailsModal__QuickNavButton {
    .MyButton__Inner__IconRight {
        // override with styles for .MyButton__Inner__IconLeft;
        font-size: 20px;
        opacity: 0.4;
    }
}
