@import '../../../../variables.scss';

.AcountingConnectionDetailModal__Frame {
    width: 800px;
}

.AcountingConnectionDetailModal__Details__Header {
    font-size: 18px;
    margin: 24px 0 12px;
    font-weight: $font-weight-semi-bold;
}

.AcountingConnectionDetailModal__Details__Empty {
    color: $color-grey-base;
}

.AcountingConnectionDetailModal__RawDataModal__Frame {
    width: 1000px;
    display: flex;
    flex-direction: column;
}

.AcountingConnectionDetailModal__RawDataModal__Body {
    display: flex;
}

.AcountingConnectionDetailModal__RawDataModal__Data {
    border: 0;
    background-color: $color-secondary-lighter;
    color: $color-secondary-darker;
    font-size: 12px;
    padding: 12px;
    width: 100%;
    resize: none;
}
