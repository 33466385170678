@import '../../../variables.scss';

.ToggleCriteriaField {
    position: relative;
    width: 80px;
    min-width: 80px;
}
.ToggleCriteriaField__Label {
    overflow: visible;
}
.ToggleCriteriaField__OutlinedInput {
    height: 40px;
    width: 80px;
    min-width: 80px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
}
.ToggleCriteriaField__SwitchWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
