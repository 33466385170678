@import '../../../../variables.scss';

.ShipmentWorkItemsProgress {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
}
.ShipmentWorkItemsProgress__Empty {
    color: rgba(0, 0, 0, 0.5);
}
.ShipmentWorkItemsProgress__Counts {
    font-size: 10px;
    white-space: nowrap;
}
.ShipmentWorkItemsProgress__Bar {
    background-color: $color-grey-lighter;
    width: 100%;
    min-width: 60px;
    height: 6px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    .progress {
        height: 100%;
        background-color: $color-warning-base;
        &.complete {
            background-color: $color-checked-green;
        }
    }
}
