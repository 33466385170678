@import '../../../../variables.scss';

.StocktakeCreateModal__Frame {
    width: 600px;
}

.StocktakeCreateModal__CheckboxTree {
    margin: 8px 0;
}

.StocktakeCreateModal__CheckboxTreeEmpty {
    font-size: 13px;
    color: $color-grey-base;
    padding: 4px 0;
}
