@import '../../../../variables.scss';

.InventoryReceiptStatusBadge {
    border-radius: 24px;
    display: inline-block;
    color: white;
    background-color: $color-secondary-base;
    border: 1px solid transparent;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;

    border-radius: 12px;
    padding: 0 12px;

    &.InventoryReceiptStatusBadge--small {
        padding: 0 12px;
        font-size: 12px;
    }

    &.InventoryReceiptStatusBadge--DRAFT {
        border-color: $color-checked-green;
        color: $color-checked-green;
        background-color: white;
    }

    &.InventoryReceiptStatusBadge--PROCESSED {
        background-color: $color-checked-green;
        border-color: $color-checked-green;
    }

    &.InventoryReceiptStatusBadge--Archived {
        background-color: $color-warning-light;
        border-color: $color-warning-light;
        color: $color-warning-dark;
    }
}
