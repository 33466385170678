@import '../../../../variables.scss';

.ScheduleWorkOrderRow {
    display: flex;
    padding: 2px 12px 2px 0;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: $color-secondary-lighter;
    border: 1px solid transparentize($color: $color-secondary-base, $amount: 0.7);
    box-shadow: 0px 4px 6px -8px black;
    white-space: nowrap;
    transition: opacity 0.3s ease;

    @media #{$medium-down} {
        gap: 12px;
    }
    @media #{$large-only} {
        gap: 24px;
    }

    .ScheduleMain__DateBlock__RowContainer.collapsed & {
        opacity: 0;
    }

    &--highlight {
        background-color: $color-warning-light;
    }
}

.ScheduleWorkOrderRow__ClickableWrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 2px 12px 2px 0;
    white-space: nowrap;
    cursor: pointer;

    @media #{$medium-down} {
        gap: 12px;
    }
    @media #{$large-only} {
        gap: 24px;
    }
}

.ScheduleWorkOrderRow__Cell {
    overflow: hidden;
    white-space: nowrap;

    .ScheduleWorkOrderRow__ClickableWrapper & {
        flex: 1;
    }
}

.ScheduleWorkOrderRow__Cell__Label {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
}

.ScheduleWorkOrderRow__HandleCell {
    font-size: 20px;
    min-width: 20px;
}

.ScheduleWorkOrderRow__DragHandle {
    height: 48px;
    color: $color-secondary-base;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px 0 24px;
}

.ScheduleWorkOrderRow__DragHandle {
    cursor: grab;
    .ScheduleMain__DateBlock--Loading & {
        cursor: default;
    }
}

.ScheduleWorkOrderRow__SlackDays {
    &.negative {
        .ScheduleWorkOrderRow__Cell__Value {
            color: $color-danger-base;
            font-weight: $font-weight-bold;
        }
    }
}

.ScheduleWorkOrderRow__Menu {
    font-size: 24px;
    min-width: 24px;
    height: 48px;
    color: $color-secondary-base;
    display: flex;
    align-items: center;
    justify-content: center;

    .MyMenuButton {
        border: 1px solid transparent;
        border-bottom: 0;
        &.menu-open {
            background-color: white;
            border-color: $color-grey-base;
        }
        .MyButton {
            &.Mui-disabled {
                background-color: transparent;
            }
        }
    }
}
