@import '../../variables';
.AppHeader {
    padding: 6px 12px;
    background-color: white;
    border-bottom: 1px solid $color-grey-lighter;
    box-shadow: -4px 1px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AppHeader__NavLeft__MobileMenuButton {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    min-width: 40px;
    padding: 0;
}

.AppHeader__NavLeft__MockBadge {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    padding: 0px 12px;
    white-space: nowrap;
    color: #652300;
    background-color: #ff8b32;
    border: 1px solid #cd4800;

    @media #{$small-down} {
        font-size: 14px;
    }
    @media #{$medium-up} {
        font-size: 16px;
    }
}

.AppHeader__NavLeft__MockBadge__TooltipContent {
    font-size: 12px;
}

.AppHeader__NavRight {
    display: flex;
    flex-direction: row-reverse;
}

.AppHeader__NavRight__UserMenuButton {
    .MuiButton-root {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        min-width: 0;
        padding: 0;
    }
}

.AppHeader__NavRight__UserMenuButton__Menu {
    min-width: 150px;
}
