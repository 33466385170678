@import '../../../../variables.scss';

.SalesOrderCancelDialog__Frame {
    max-width: 592px;
    display: flex;
}

.SalesOrderCancelDialog__Header {
    font-size: 24px;
    font-weight: $font-weight-bold;
    text-align: center;
    margin-bottom: 24px;
}

.SalesOrderCancelDialog__WorkItems {
    justify-content: center;
    margin-bottom: 24px;
}

.SalesOrderCancelDialog__WorkItems__Message {
    font-size: 16px;
    margin-bottom: 24px;
    text-align: center;
}

.SalesOrderCancelDialog__WorkItems__Item {
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: $font-weight-normal;
    position: relative;
    justify-content: center;

    .tuid {
        margin: 0;
        color: $color-grey-light;
        font-weight: $font-weight-normal;
    }
}

.SalesOrderCancelDialog__Message {
    font-size: 16px;
    margin-bottom: 24px;
    text-align: center;
}
.SalesOrderCancelDialog__Select {
    align-items: center;
}

.SalesOrderCancelDialog__Buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    width: 100%;
    gap: 8px;
}
