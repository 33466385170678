@import '../../../../variables.scss';

.SalesOrderSplitDialog {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    h2,
    p {
        margin: 0;
    }
    .MyButton {
        min-width: 128px;
    }
}

.SalesOrderSplitDialog__Buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    width: 100%;
    gap: 8px;
}
