@import '../../../../variables.scss';

.CustomerDetailModal__Frame {
    width: 700px;
}

.CustomerDetailModal__PageHeader__Actions {
    display: flex;
    gap: 6px;
}

.CustomerDetailModal__Body {
    .PropertyEdit__Label,
    .PropertyDisplay__Label {
        width: 200px;
        min-width: 200px;
    }
}
