@import '../../variables.scss';

.MySkeleton {
    display: flex;
    flex-direction: column;
    // gap: 4px;

    .MySkeleton__Line {
        transform: scale(1, 0.8);
        border-radius: 3px;
        // opacity: 0.7;
        filter: brightness(200%);
    }
}
