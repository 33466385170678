@import '../../variables.scss';

.MyCalendar {
}

.MyCalendarDay {
    margin: 0;
    width: 40px;

    &.selected-week {
        background-color: $color-primary-base;
        color: white;
        border-radius: 0;

        &.first {
            border-radius: 20px 0 0 20px;
        }
        &.last {
            border-radius: 0 20px 20px 0;
        }
    }
}

.MyCalendarDay__Button {
    margin: 0;
    font-size: 13px;
    &:focus,
    &:hover {
        background-color: transparentize($color: $color-primary-light, $amount: 0.8);
    }
    &.Mui-selected {
        background-color: lighten($color-primary-base, 5%);

        &:focus,
        &:hover {
            background-color: lighten($color-primary-base, 5%);
        }
    }
    &.MuiPickersDay-dayOutsideMonth {
        color: rgba(0, 0, 0, 0.3);
    }

    .selected-week & {
        color: white;
        &.MuiPickersDay-dayOutsideMonth {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}
