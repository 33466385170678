@import '../../../../variables.scss';

.ScheduleMain {
    max-width: 1000px;
    margin-top: -16px;
}

.ScheduleMain__PageHeader {
    padding-bottom: 24px;
    border-bottom: 1px solid $color-grey-light;
    margin-bottom: 32px;
    align-items: flex-end;

    .PageHeader__ContentContainer {
        flex-direction: column;
        align-items: flex-end;
    }
}

.ScheduleMain__DatesList__LoadingIndicator {
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .MyLinearProgress {
        width: 300px;
    }
}

.ScheduleMain__DatesList__Error {
    background-color: white;
    padding: 24px;
    border-radius: 5px;
}

.ScheduleMain__DateBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    background-color: white;
    border-radius: 9px;
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 6px -8px black;

    max-height: 2000px;
    transition: max-height 0.3s ease;

    &--Loading {
        opacity: 0.5;
    }

    &--Collapsed {
        max-height: 76px;
        box-sizing: border-box;
        overflow: hidden;
    }
}

.ScheduleMain__DateBlock__Header {
    display: flex;
    gap: 12px;
    align-items: center;
}

.ScheduleMain__DateBlock__Header__TodayBadge {
    font-weight: bold;
    background-color: $color-accent-base;
    color: white;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 5px;
}

.ScheduleMain__DateBlock__Header__Title {
    font-size: 18px;
    margin: 0;
    font-weight: $font-weight-normal;
    z-index: 1;
    flex: 1;
}

.ScheduleMain__DateBlock__Header__Counts {
    font-size: 13px;
}

.ScheduleMain__DateBlock__Header__ExpandButton {
    min-width: 0;
    height: 24px;
    padding: 6px;

    .MyButton__Inner__IconRight {
        font-size: 16px;
    }
}

.ScheduleMain__DateBlock__RowContainer {
    position: relative;
    overflow: auto;
    margin-top: 24px;
    transition: opacity 0.3s ease;

    &--Empty {
        margin-top: 0;
    }

    .ScheduleMain__DateBlock--Collapsed & {
        opacity: 0;
    }

    .smooth-dnd-container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        min-height: 58px;
    }
}

.ScheduleMain__DateBlock__RowContainer__EmptyMessage {
    color: $color-grey-base;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: none !important;
}
