@import '../../variables.scss';

.FieldValidator__ErrorWrapper {
    overflow: hidden;
    color: $color-error;

    // default hidden state
    opacity: 0;
    max-height: 0;
    padding: 0;
    transition: opacity 0.3s ease, max-height 0.3s ease;

    // visible state
    &.show {
        opacity: 1;
        max-height: 200px;
        // padding-top: 8px;
    }

    .icon {
        font-size: 24px;
        padding-top: 4px;
        display: none;
    }

    .message {
        font-size: 14px;
        // padding-top: 4px;
        flex: 1;
        text-align: left;
    }
}
