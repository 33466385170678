@import '../../../../variables.scss';

.DocumentFileIcon {
    display: inline-block;
    font-size: 22px;
    line-height: 22px;
    vertical-align: middle;
}

.DocumentFileIcon--word {
    color: $color-primary-dark;
}
.DocumentFileIcon--excel {
    color: #286a33;
}
.DocumentFileIcon--pdf {
    color: $color-danger-base;
}
.DocumentFileIcon--image {
    color: $color-grey-base;
}
