@import '../../../../variables.scss';

.Login {
    width: 512px;
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.Login__Logo {
    width: 190px;
    margin-bottom: 16px;
}

.Login__Form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.Login__ForgotWrapper {
    text-align: center;
}
