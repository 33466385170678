@import '../../variables.scss';

.PropertyEditAutocomplete__InputWrapper {
    width: 100%;
}
.PropertyEditAutocomplete__Input {
    width: 100%;
}
.PropertyEditAutocomplete__Input--MultiSelect {
    width: 100%;
}
