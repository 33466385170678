@import '../../../../variables.scss';

.WorkItemStepList {
    display: flex;
    flex-direction: column;
}

.WorkItemStepList__Step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 4px 12px;
    &:nth-child(odd) {
        background-color: $color-secondary-lightest;
    }

    .step-name {
        flex: 1;
    }
    .workstation {
        color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
    }
    .chevron {
        font-size: 16px;
        // opacity: 0.7;
        color: $color-secondary-base;
    }
}
