@import '../../../../variables.scss';

.LocationInventoryTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}

.DataTable__EmptyStateWrapper {
    padding: 12px 0;
}
