@import 'variables.scss';

.Layout__Container {
    @media #{$medium-up} {
        margin-left: $mainNavWidth;
    }
}
.Layout__Main {
    @media #{$small-down} {
        padding: 16px 12px;
        padding-top: $topBarHeight + 24px;
    }
    @media #{$medium-up} {
        padding: 32px;
    }
}
