@import '../../../../variables.scss';

.SalesOrderHistoryTab__DataTable {
    .DataTable__DataCell:first-child {
        padding-left: 12px;
    }
    .DataTable__DataCell:last-child {
        padding-right: 12px;
    }
}
