@import '../../../../variables.scss';

.WorkstationSelector {
    max-width: 600px;
}

.WorkstationSelector__FilterBar {
    margin-bottom: 16px;

    .DataTableFilters__Field {
        width: 100%;

        .MyTextInput {
            width: 100%;
        }
    }
}

.WorkstationSelector__DataTable {
    border: 1px solid $color-secondary-light;

    .DataTable__DataCell {
        font-size: 14px;
    }
}
