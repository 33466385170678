@import '../../../../variables.scss';

.SalesOrderStatusBadge {
    border-radius: 24px;
    display: inline-block;
    color: white;
    background-color: $color-secondary-base;
    text-align: center;
    white-space: nowrap;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

    // size: normal
    padding: 2px 16px;
    font-size: 14px;

    &.SalesOrderStatusBadge--small {
        padding: 2px 12px;
        font-size: 12px;
    }
}
