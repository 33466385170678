@import '../../../../variables.scss';

.InventoryGroupsTable__FilterBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}

.InventoryGroupsTable__FilterBar__Actions {
    display: flex;
    flex-direction: row;
    gap: 6px;
}
