@import '../../variables.scss';

.LocationsPage__TableHeader {
    margin-top: 32px;
    font-size: 18px;
    font-weight: $font-weight-semi-bold;
}

.LocationsPage__NoChildren {
    text-align: center;
}
