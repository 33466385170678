@import '../../variables.scss';

// @keyframes modal_reveal {
@keyframes modal_reveal {
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(0);
    }
}

.MyModal__ScrollContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px 0;

    @media #{$small-down} {
        padding: 0;
    }
}

.MyModal__Frame {
    background-color: white;
    position: relative;
    padding: 32px;
    border-radius: 12px;
    margin: auto;

    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2);
    outline: 0;

    max-width: calc(100% - 112px);
    @media #{$small-down} {
        // border-radius: 0;
        // max-width: 100%;
        // min-height: 100vh;
    }
    @media #{$medium-up} {
    }

    &--full-height {
        min-height: $modal-full-height;
    }
}

.MyModal__Frame--animate-in {
    animation: modal_reveal 0.2s ease-out;

    // disable animation in storybook
    .sb-show-main & {
        animation: none;
    }
}

.MyModal__Body {
    flex: 1;
}

.MyModal__LoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    flex: 1;

    .MuiLinearProgress-root {
        width: 100px;
    }
}

.MyModal__Error {
    flex: 1;

    @media #{$small-down} {
        padding: 64px 0;
    }
    @media #{$medium-up} {
        padding: 64px;
    }
}

.MyModal__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-lighter;
    padding-bottom: 24px;
    margin-bottom: 24px;

    .PageHeader {
        flex: 1;
        margin-bottom: 0;
    }
}

.MyModal__CloseButton {
    border: 0;
    min-width: 0;
    position: absolute;
    right: -52px;
    top: 0;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    height: auto;
    font-weight: normal;
    padding: 4px 8px;

    .MyButton__Inner__Label {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &:hover {
        background-color: transparent;
    }

    .icon {
        font-size: 28px;
        color: white;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
    }

    // Hide close button on all but top-most modal
    .MyModal:not(:last-child) & {
        opacity: 0;
    }
}
