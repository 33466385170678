@import '../../../../variables.scss';

.ShipmentDetailModal__Frame {
    width: 900px;
}

.ShipmentDetailModal__PageHeader__Actions {
    display: flex;
    gap: 6px;
}

.ShipmentDetailModal__Body {
    .PropertyEdit__Label,
    .PropertyDisplay__Label {
        width: 120px;
        min-width: 120px;
    }
}

.ShipmentDetailModal__Tabs {
    margin-top: 24px;
    .MyTabs__Tabs__TabButton--work-items {
        flex-direction: column;
        gap: 8px;

        .ShipmentWorkItemsProgress__Counts {
            color: $color-grey-base;
        }
    }
}

.ShipmentDetailModal__WorkOrdersTab__WorkItemStatusCell {
    display: flex;
    justify-content: flex-start;
}
