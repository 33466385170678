@import '../../../../variables.scss';

.ShipmentStatusBadge {
    border-radius: 24px;
    display: inline-block;
    color: white;
    background-color: $color-secondary-base;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;

    // size: normal
    padding: 4px 16px;
    font-size: 14px;

    &.ShipmentStatusBadge--small {
        padding: 2px 12px;
        font-size: 12px;
    }
    &.ShipmentStatusBadge--tiny {
        padding: 0px 6px;
        font-size: 11px;
    }

    &.OPEN {
        background-color: white;
        color: $color-primary-base;
        border: 1px solid $color-primary-light;
    }
    &.LOCKED {
        background-color: $color-primary-light;
    }
    &.COMPLETED {
        background-color: $color-accent-lighter;
        color: $color-accent-dark;
    }
}
