@import '../../../../variables.scss';

.SalesItemBulkAssignStatusDialog {
    padding: 32px;
    width: 380px;
}

.SalesItemBulkAssignStatusDialog__PropertyContainer {
    width: 100%;
    .PropertyEditSelect__Input,
    .PropertyEditSelect__MySelectInput {
        width: 100%;
    }
}

.SalesItemBulkAssignStatusDialog__ProgressContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    margin-top: 24px;
}

.SalesItemBulkAssignStatusDialog__Buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
    margin-top: 32px;
}

.SalesItemBulkAssignStatusDialog__Buttons__Save {
    min-width: 170px;
    flex: 1;
}

.SalesItemBulkAssignStatusDialog__Buttons__Cancel {
    flex: 1;
}
