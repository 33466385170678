@import '../../variables.scss';

.DataTableFilters {
    width: 100%;
}

.DataTableFilters__Filters {
    display: flex;
    flex-direction: row;
    gap: 8px 6px;
    flex-wrap: wrap;
}

.DataTableFilters__FilteredResetPanel {
    padding: 0 6px 0 24px;
    margin-top: 8px;
    background-color: $color-primary-lightest;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: $color-secondary-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MyButton.DataTableFilters__ResetButton {
    padding: 8px 12px;
}
