@import '../../../../variables.scss';

.InventoryLocationJoinDetailModal__Frame {
    width: 900px;
}

.InventoryLocationJoinDetailModal__Header {
    border-bottom: none;
    padding: 0;
}

.InventoryLocationJoinDetailModal__Title {
    font-size: 24px;
    font-weight: $font-weight-bold;
    display: flex;
    padding: 0 0 16px;
}

.InventoryLocationJoinDetailModal__Title__Context {
    color: $color-grey-light;
    font-weight: normal;
}

.InventoryLocationJoinDetailModal__Title__Icon,
.InventoryLocationJoinDetailModal__Icon {
    margin-bottom: -0.15em;
}

.InventoryLocationJoinDetailModal__LocationPaths {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px 2px;
    color: $color-link-base;
    .InventoryLocationJoinDetailModal__ParentLocation {
        color: $color-secondary-darker;
    }
    margin: 10px 0;
}

.InventoryLocationJoinDetailModal__Title__EntityLink {
    color: $color-link-base;
}

.InventoryLocationJoinDetailModal__Title__Description {
    font-size: 14px;
    color: $color-grey-base;
    font-weight: normal;
}

.InventoryLocationJoinDetailModal__CardWrapper {
    display: flex;
    justify-content: space-evenly;
    gap: 16px;
}

.InventoryLocationJoinDetailModal__Card {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    padding: 12px 16px;
    background: $color-secondary-lighter;
    position: relative;
    text-align: center;
    width: 120px;
}

.InventoryLocationJoinDetailModal__Card__Label {
    color: $color-secondary-base;
    font-size: 16px;
}

.InventoryLocationJoinDetailModal__Card__Count {
    font-size: 24px;
    font-weight: $font-weight-bold;
    line-height: 1;
}

.InventoryLocationJoinDetailModal__Containers {
    display: table;
    width: 100%;
}

.InventoryLocationJoinDetailModal__Containers__Row--header {
    font-weight: $font-weight-bold;
}

.InventoryLocationJoinDetailModal__Containers__Row {
    display: table-row;
    text-align: end;

    & > * {
        display: table-cell;
        padding: 4px 16px;
        &:first-child {
            text-align: start;
        }
    }

    background-color: $color-secondary-lighter;
    &:nth-child(even) {
        background-color: $color-secondary-lightest;
    }
}
