@import '../../../../variables.scss';

.DocumentUploadModal__Frame {
    width: 600px;
}

.DocumentUploadModal__Body {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.DocumentUploadModal__Dropzone {
    border: 2px dashed $color-grey-lighter;
    background-color: $color-grey-lightest;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;

    p {
        margin: 0;
        text-align: center;
    }
}

.DocumentUploadModal__FileList {
    list-style: none;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
}

.DocumentUploadModal__FileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    padding: 4px 6px 4px 12px;
    background-color: white; //$color-secondary-lighter;
    font-size: 13px;

    &:nth-child(even) {
        background-color: $color-secondary-lightest;
    }
    &.DocumentUploadModal__FileItem--disabled {
        filter: saturate(0);
        background-color: $color-grey-lightest;
        &:nth-child(even) {
            background-color: $color-grey-lighter;
        }
    }
}

.DocumentUploadModal__FileItem__Name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DocumentUploadModal__FileItem__RemoveButton {
    padding: 2px;
    min-width: 0px;
    background-color: transparent !important;
}

.DocumentUploadModal__Footer {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    border-top: 1px solid $color-grey-lighter;
    padding-top: 24px;
}
